import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./Navbar.scss";

const menuItems = [
  {
    title: "experiencesTitle",
    url: "#experiences",
  },
  {
    title: "projectsTitle",
    url: "#projects",
  },
];

const flags = {
  pt: require("../../images/icons/brazil.svg"),
  en: require("../../images/icons/usa.svg"),
  es: require("../../images/icons/spain.svg"),
};

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  const onLanguageSelect = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  return (
    <div className="navbar-wrapper">
      <nav className="navbar">
        <a className="navbar__logo" href="https://lucasribeiro.dev/">
          Lucas R.
        </a>

        <div className="navbar__container">
          <div className="navbar__items">
            {menuItems.map((item, index) => {
              return (
                <a key={index} className="item" href={item.url}>
                  {t(item.title)}
                </a>
              );
            })}
          </div>

          <div className="navbar__flags">
            <img
              onClick={() => onLanguageSelect("pt")}
              className={language === "pt" ? "flag selected" : "flag"}
              src={flags.pt}
              alt="Brazilian national flag"
            />

            <img
              onClick={() => onLanguageSelect("en")}
              className={language === "en" ? "flag selected" : "flag"}
              src={flags.en}
              alt="United Kingdom national flag"
            />

            <img
              onClick={() => onLanguageSelect("es")}
              className={language === "es" ? "flag selected" : "flag"}
              src={flags.es}
              alt="Spain national flag"
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
