import React, { useEffect, useState } from "react";
import "./BackToTop.scss";

const BackToTop = () => {
  const [scroll, setScroll] = useState(0);
  const [hidden, setHidden] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (scroll > 500) setHidden(false);
    else setHidden(true);
  }, [scroll]);

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      setScroll(window.scrollY);
      console.log(window.scrollY)
    });
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`back-to-top ${hidden ? "hidden" : ""}`}
    >
      <i className="fa fa-chevron-up arrow-up"></i>
    </button>
  );
};

export default BackToTop;
