import React from "react";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy__name">Katser Mobile</h1>
      <h1 className="privacy-policy__title">Política de Privacidade</h1>
      <div>
        Na Katser Mobile não coletamos quaisquer dados pessoais dos usuários
        para quaisquer fins, seja para uso próprio ou comercial. De todo modo,
        estabelecemos a presente Política de Privacidade para segurança dos
        usuários que façam uso dos nossos aplicativos. Ao utilizar nossos
        serviços, você entende que, apesar de não coletar quaisquer dados e
        informações, todos os nossos aplicativos seguem rigorosamente as normas
        da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX –
        incluídos pela EC 115/2022), das normas de Proteção de Dados (LGPD, Lei
        Federal 13.709/2018), das disposições consumeristas da Lei Federal
        8078/1990 e as demais normas do ordenamento jurídico brasileiro
        aplicáveis. Dessa forma, a Katser Mobile obriga-se ao disposto na
        presente Política de Privacidade.
      </div>

      <h2 className="privacy-policy__title">
        Quais dados coletamos sobre você e para qual finalidade?
      </h2>
      <div>
        Nossos aplicativos NÃO coletam quaisquer dados sobre você para nenhuma
        finalidade. Se assim o for feito no futuro, esta Política será
        prontamente ajustada para manifestar tais mudanças.
      </div>

      <h2 className="privacy-policy__title">Alteração desta Política de Privacidade</h2>
      <div>
        A atual versão da Política de Privacidade foi formulada e atualizada
        pela última vez em: 22 de junho de 2022. Reservamos o direito de
        modificar essa Política de Privacidade a qualquer tempo, principalmente
        em função da adequação a eventuais alterações feitas em nossos
        aplicativos ou em âmbito legislativo. Recomendamos que você a revise com
        frequência. Eventuais alterações entrarão em vigor a partir de sua
        publicação em nosso site. Ao utilizar nossos aplicativos após tais
        modificações, você as consente. usuário/cliente. Caso tenha dúvidas
        sobre esta Política de Privacidade, você pode entrar em contato através
        do seguinte canal: contato@lucasribeiro.dev (Lucas Ribeiro)
      </div>
    </div>
  );
};

export default PrivacyPolicy;
