import React from "react";
import "./Section.scss";

export const Section = ({ children, backgroundColor = "#1f1f1f", id }) => {
  return (
    <div id={id} className="section" style={{ backgroundColor }}>
      {children}
    </div>
  );
};
