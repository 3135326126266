export default {
  experiences: [
    {
      name: {
        en: "Luizalabs",
        pt: "Luizalabs",
        es: "Luizalabs",
      },
      description: {
        pt: "<p>Atuo no time de Lojas da Netshoes, onde minhas responsabilidades envolvem a implementação de novas funcionalidades, refatoração de projetos legados, implementação de melhorias em biblioteca de componentes (design system), melhorias de SEO e criação de novas lojas online dentro da plataforma. </p><p>Algumas das minhas contribuições importantes incluem a implementação de um sistema de cache em Redis para resolver problemas de falhas em requisições para API do Instagram, a refatoração de projeto legado usando Vue e NestJS, implementação de uma série de melhorias que contribuíram significativamente para o ranqueamento das páginas nos mecanismos de SEO, e o desenvolvimento de novas funcionalidades a partir de testes A/B.</p>",
        en: "<p>As a member of the Online Stores squad at Netshoes, my responsibilities include implementing new features, primarily through A/B tests, improving SEO, refactoring legacy projects, working on a components library (design system), and expanding the platform by creating new e-commerce sites. </p><p>Some of my importante contributions include the implementation of a Redis caching to resolve Instagram API request issues, refactoring a legacy project using Vue and NestJS, implementing a range of improvements and functionalities that led to enhanced SEO page rankings, and developing new features through A/B testing.</p>",
        es: "<p>Como miembro del equipo de Tiendas en Línea en Netshoes, mis responsabilidades incluyen la implementación de nuevas características, principalmente a través de pruebas A/B, la mejora del SEO, la refactorización de proyectos heredados, el trabajo en una biblioteca de componentes (sistema de diseño) y la expansión de la plataforma mediante la creación de nuevos sitios de comercio electrónico. </p><p>Algunas de mis contribuciones importantes incluyen la implementación de un sistema de almacenamiento en caché con Redis para resolver problemas de solicitudes de API de Instagram, la refactorización de un proyecto heredado utilizando Vue y NestJS, la implementación de una variedad de mejoras y funcionalidades que condujeron a una mejora en la clasificación de páginas en SEO y el desarrollo de nuevas características a través de pruebas A/B.</p>",
      },
      role: {
        en: "Front-end Developer",
        pt: "Desenvolvedor Front-end",
        es: "Desarrollador Front-end",
      },
      skills: [
        "Vue.js",
        "React",
        "JavaScript",
        "TypeScript",
        "Nest",
        "Jest",
        "Node.js",
        "SASS",
        "SEO",
      ],
      url: "https://www.netshoes.com.br/",
      startedTime: {
        en: "February 2022",
        pt: "Fevereiro 2022",
        es: "Febrero 2022",
      },
      endedTime: {
        en: "present",
        pt: "até o momento",
        es: "hasta la fecha",
      },
      image: require("../images/experiences/luizalabs.png"),
    },
    {
      name: {
        en: "Atla Education",
        pt: "Atla Ensino",
        es: "Atla Educación",
      },
      description: {
        pt: "<p>Como desenvolvedor full-stack na Atla, fui responsável por desenvolver novas funcionalidades de ponta a ponta, desde a implementação da interface do usuário usando Angular e Ionic, construção do back-end com Node e Express e a integração entre ambos. </p><p>Minhas principais contribuições incluem o desenvolvimento completo (front-end e back-end) de um sistema de blog, abrangendo a criação de posts, comentários, curtidas e compartilhamentos. Também tive um papel importante na implementação da interface de usuário para várias novas funcionalidades, como o gerenciamento de tarefas de alunos, controle de presenças e avaliações</p>",
        en: "<p>As a full-stack developer at Atla, I was primarily responsible for developing end-to-end functionalities by implementing the user interface using Angular and Ionic, building the backend with Node and Express, and integrating the front-end with the back-end. </p><p>Some of my notable contributions include the development of a blog feature, which covers actions such as creating and posting articles, comments, likes, and shares. This work involved tasks related to database implementation, backend routing, Swagger documentation, as well as the development and integration of user interfaces. I also played a pivotal role in implementing the user interface for various new features, such as student task management, attendance control, and assessments.</p>",
        es: "<p>Como desarrollador full-stack en Atla, mi responsabilidad principal fue desarrollar funcionalidades de extremo a extremo mediante la implementación de la interfaz de usuario con Angular e Ionic, construir el backend con Node y Express, e integrar el front-end con el back-end. </p><p>Algunas de mis contribuciones destacadas incluyen el desarrollo de una función de blog, que abarca acciones como crear y publicar artículos, comentarios, me gusta y compartidos. Este trabajo implicó tareas relacionadas con la implementación de la base de datos, enrutamiento del backend, documentación de Swagger, así como el desarrollo e integración de interfaces de usuario. También desempeñé un papel crucial en la implementación de la interfaz de usuario para diversas funciones nuevas, como la gestión de tareas de estudiantes, el control de asistencia y las evaluaciones.</p>",
      },
      role: {
        en: "Full-stack Developer",
        pt: "Desenvolvedor Full-stack",
        es: "Desarrollador Full-stack",
      },
      url: "https://atlaensino.com/",
      skills: [
        "Angular",
        "Ionic",
        "TypeScript",
        "Express",
        "Node.js",
        "MongoDB",
      ],
      startedTime: {
        en: "January 2020",
        pt: "Janeiro 2020",
        es: "Enero 2020",
      },
      endedTime: {
        en: "August 2020",
        pt: "Agosto 2020",
        es: "Agosto 2020",
      },
      image: require("../images/experiences/atla.png"),
    },
  ],

  projects: [
    {
      name: {
        en: "Football Quiz: Guess the Player",
        pt: "Quiz de Futebol: Advinhe o Jogador",
        es: "Cuestionario de Fútbol: Adivina el Jugador",
      },
      description: {
        en: "<p>A mobile game in which players can guess the footballer and complete a collection of cards. Up until now, it has received over 50,000 downloads and has a rating score of 4.4 based on more than 200 reviews on Google Play. </p><p>The creation of this game involved the development of a user-friendly interface, the creation of reusable components, the implementation of data persistence with Async Storage and Redux, the incorporation of internationalization using i18n, and the integration of features such as In-App Purchases, Advertisements, and metric collection with Firebase.</p>",
        pt: "<p>Um jogo mobile no qual os jogadores podem adivinhar o jogador de futebol e completar uma coleção de cartas. Até o momento, possui mais de 50.000 downloads e uma classificação de 4,4 com mais de 200 avaliações na Google Play. </p><p>A criação desse jogo envolveu o desenvolvimento da interface de usuário, a criação de componentes reutilizáveis, implementação de persistência de dados com Async Storage e Redux, internacionalização usando i18n e a integração de funcionalidades como Compras no App, Anúncios e coleta de métricas de uso com o Firebase.</p>",
        es: "<p>Un juego móvil en el que los jugadores pueden adivinar al futbolista y completar una colección de cartas. Hasta el momento, ha recibido más de 50,000 descargas y tiene una puntuación de 4.4 basada en más de 200 reseñas en Google Play. </p><p>La creación de este juego involucró el desarrollo de una interfaz amigable para el usuario, la creación de componentes reutilizables, la implementación de la persistencia de datos con Async Storage y Redux, la incorporación de la internacionalización mediante i18n y la integración de características como compras en la aplicación, publicidad y la recopilación de métricas con Firebase.</p>",
      },
      skills: [
        "React Native",
        "Redux (Async Storage)",
        "Styled-components",
        "JavaScript",
        "i18n",
        "Firebase",
      ],
      url: "https://play.google.com/store/apps/details?id=com.whoistheplayer",
      startedTime: {
        en: "December 2021",
        pt: "Dezembro 2021",
        es: "Diciembre 2021",
      },
      endedTime: {
        en: "present",
        pt: "até o momento",
        es: "hasta la fecha",
      },
      image: require("../images/projects/whoistheplayer.png"),
    },
    {
      name: {
        en: "Textify: Translator & AI Text Generator",
        pt: "Textify: Tradutor & Gerador de Texto IA",
        es: "Textify: Traductor y Generador de Texto con IA",
      },
      description: {
        en: "<p>A mobile app that enables intelligent translation and text generation in various languages, including Portuguese, English, Spanish, Italian, French, and German. Users can select the desired text type, whether it's professional, academic, scientific, journalistic, or legal. Additionally, the app offers the option to choose the writing style between informal, neutral, and formal. </p><p>Creating this project involved developing a beautiful and user-friendly interface, creating reusable components, integrating metric collection with Firebase, and following best practices for project structure and architecture.</p>",
        pt: "<p>Um aplicativo que permite a tradução inteligente e geração de texto em várias idiomas como português, inglês, espanhol, italiano, francês e alemão. Os usuários podem selecionar o tipo de texto desejado, podendo ser profissional, acadêmico, científico, jornalístico ou jurídico, além de ter a opção de escolher o estilo de escrita, podendo ser informal, neutro ou formal. </p><p>A criação desse app envolveu o desenvolvimento da interface de usuário, a criação de componentes reutilizáveis, integração de coleta de métricas com o Firebase e o uso das melhores práticas de estrutura e arquitetura de projetos.</p>",
        es: "<p>Una aplicación móvil que permite la traducción inteligente y la generación de texto en varios idiomas, incluyendo portugués, inglés, español, italiano, francés y alemán. Los usuarios pueden seleccionar el tipo de texto deseado, ya sea profesional, académico, científico, periodístico o legal. Además, la aplicación ofrece la opción de elegir el estilo de escritura entre informal, neutral y formal. </p><p>La creación de este proyecto implicó el desarrollo de una interfaz hermosa y amigable para el usuario, la creación de componentes reutilizables, la integración de la recopilación de métricas con Firebase y el seguimiento de las mejores prácticas en la estructura y arquitectura del proyecto.</p>",
      },
      url: "https://play.google.com/store/apps/details?id=com.dynamictranslate",
      skills: ["React Native", "TypeScript", "Firebase"],
      startedTime: {
        en: "June 2023",
        pt: "Junho 2023",
        es: "Junio 2023",
      },
      endedTime: {
        en: "present",
        pt: "até o momento",
        es: "hasta la fecha",
      },
      image: require("../images/projects/textify.png"),
    },
    {
      name: {
        en: "Brumadinho Alert",
        pt: "Alerta Brumadinho",
        es: "Alerta Brumadinho",
      },
      description: {
        en: "<p>A web application for reporting environmental incidents aimed at assisting the people of Brumadinho, a Brazilian city affected by the collapse of an ore extraction dam in 2019. The application allows user registration, public entity registration, viewing of incidents on a timeline, creation, search, validation, commenting, and supporting incidents. The project was developed in partnership with NIC.br, Ceweb, Softex, and MCTIC. </p><p>I was responsible for designing and developing the user-friendly interface using React, integrating it with the application's back-end (API), leading the project development using agile methodologies, and presenting the project in crucial meetings involving public entities and business professionals.</p>",
        pt: "<p>Sistema web para reportar incidentes ambientais, desenvolvido com objetivo de ajudar a população de Brumadinho afetada pelo rompimento da barragem de extração de minério em 2019. A aplicação permite o registro de usuários, registro de entidades públicas, visualização de incidentes em uma linha do tempo, criação, busca, validação e interação nos incidentes publicados. </p><p>Fui responsável por desenvolver toda a interface de usuário em React, além deintegrá-la com o back-end da aplicação em Node. Também liderei o desenvolvimento do projeto usando SCRUM, além de ter apresentado o projeto em diversas reuniões importantes envolvendo representantes das entidades parceiras como NIC.br, Ceweb, Softex e MCTIC.</p>",
        es: "<p>Una aplicación web para informar sobre incidentes ambientales con el objetivo de ayudar a la población de Brumadinho, una ciudad brasileña afectada por el colapso de una represa de extracción de mineral en 2019. La aplicación permite el registro de usuarios, el registro de entidades públicas, la visualización de incidentes en una línea de tiempo, la creación, búsqueda, validación, comentarios y apoyo a incidentes. El proyecto se desarrolló en colaboración con NIC.br, Ceweb, Softex y MCTIC. </p><p>Fui responsable del diseño y desarrollo de la interfaz de usuario amigable utilizando React, su integración con el backend de la aplicación (API), lideré el desarrollo del proyecto utilizando metodologías ágiles y presenté el proyecto en reuniones cruciales que involucraron a entidades públicas y profesionales de negocios.</p>",
      },
      url: "https://github.com/cewebbr/mover-se_alerta-brumadinho",
      skills: ["React", "JavaScript", "Git", "SCRUM"],
      startedTime: {
        en: "May 2020",
        pt: "Maio 2020",
        es: "Mayo 2020",
      },
      endedTime: {
        en: "December 2021",
        pt: "Dezembro 2021",
        es: "Diciembre 2021",
      },
      image: require("../images/projects/brumadinho.png"),
    },
    {
      name: {
        en: "GF Estrazioni",
        pt: "GF Estrazioni",
        es: "GF Estrazioni",
      },
      description: {
        en: "A multi-language landing page for GF Estrazioni, an Italian marble extraction company. My responsibilities included designing the entire user interface using Figma, developing a user-friendly interface in React, and implementing internationalization with i18n.",
        pt: "Landing page desenvolvida para a GF Estrazioni, uma empresa italiana de extração de mármore. Nesse projeto, criei o protótipo de toda a interface com o Figma, implementei o projeto usando React e a internacionalização com o i18n.",
        es: "Una página de destino multilingüe para GF Estrazioni, una empresa italiana de extracción de mármol. Mis responsabilidades incluyeron diseñar toda la interfaz de usuario utilizando Figma, desarrollar una interfaz amigable en React e implementar la internacionalización con i18n.",
      },
      url: "https://gf-estrazioni.web.app/",
      skills: ["React", "JavaScript", "CSS"],
      startedTime: {
        en: "April 2020",
        pt: "Abril 2020",
        es: "Abril 2020",
      },
      endedTime: {
        en: "June 2020",
        pt: "Junho 2020",
        es: "Junio 2020",
      },
      image: require("../images/projects/gfestrazioni.png"),
    },
  ],
  testimonials: [
    {
      name: "Sávio Camacam",
      description: {
        en: "From the very beginning as a full-stack developer at ATLA, Lucas demonstrated the qualities of a top-notch professional, always attentive and mindful of the company's and end client's needs. Lucas consistently maintained a proactive and inquisitive approach, striving to propose the best possible solutions to problems.",
        pt: "Desde o início como desenvolvedor full-stack na ATLA, o Lucas mostrou ter o perfil de um grande profissional, sempre atento e observador às necessidades da empresa e do cliente final. Lucas sempre teve uma postura proativa e questionadora, buscando propor a melhor solução possível para os problemas apresentados.",
        es: "Desde el principio como desarrollador full-stack en ATLA, Lucas demostró tener el perfil de un profesional sobresaliente, siempre atento y observador de las necesidades de la empresa y el cliente final. Lucas mantuvo constantemente una actitud proactiva e inquisitiva, buscando proponer las mejores soluciones posibles a los problemas.",
      },
      role: {
        en: "Co-founder of Atla Education.",
        pt: "Sócio-fundador da Atla Ensino.",
        es: "Socio fundador de Atla Educación.",
      },

      image: require("../images/testimonials/savio.png"),
    },
  ],
};
