import React from "react";
import { useTranslation } from "react-i18next";
import "./Contact.scss";
import { Section } from "../Section/Section";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Section backgroundColor="#1f1f1f" shadow={true}>
      <div className="contact" id="contact">
        <div className="contact__social">
          <p className="text">{t("contactMessage")}</p>
          <div className="socials">
            <a
              href="mailto:contato@lucasribeiro.dev"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="far fa-envelope"></i>
            </a>

            <a
              href="https://www.linkedin.com/in/lucasviniciusribeiro/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-linkedin"></i>
            </a>

            <a
              href="https://github.com/lucasvribeiro"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-github"></i>
            </a>

            <a
              href="https://www.instagram.com/lucasvribeiro_/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-instagram"></i>
            </a>

            <a
              href="https://www.facebook.com/lucasvribeiro7/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
