import React from "react";
import "./Footer.scss";
import { Section } from "../Section/Section";

const Footer = () => {
  return (
    <Section backgroundColor="#1f1f1f">
      <div className="footer">
        <p className="footer__text">
          &copy; {new Date().getFullYear()} Lucas Vinicius Ribeiro.{" "}
          <span> All rights reserved.</span>
        </p>
      </div>
    </Section>
  );
};

export default Footer;
