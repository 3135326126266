import React from "react";
import { getLanguage } from "../../i18n/i18n";

import { Section } from "../Section/Section";

import data from "../../services/data";
import "./Testimonials.scss";

const testimonial = data.testimonials[0];

const Testimonials = () => {
  return (
    <Section backgroundColor="#252525" id="testimonials">
      <div className="testimonials">
        <div className="testimonials__photo">
          <img
            src={testimonial.image}
            alt={`Person: ${testimonial.name} - ${
              testimonial.role[getLanguage()]
            }`}
          />
        </div>

        <div className="testimonials__text">
          <p className="description">
            &ldquo;{testimonial.description[getLanguage()]}&rdquo;
          </p>
          <div className="person">
            <p className="person__name">{testimonial.name}</p>
            <p className="person__bullet"> • </p>
            <p className="person__role">{testimonial.role[getLanguage()]}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Testimonials;
