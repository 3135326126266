import React from "react";

import "./Timeline.scss";
import { Section } from "../Section/Section";
import { getLanguage } from "../../i18n/i18n";

const Timeline = ({ title, data, type, id }) => {
  const lang = getLanguage();

  return (
    <Section backgroundColor="#1f1f1f" id={id}>
      <div className="timeline">
        <div className="timeline-wrapper">
          <h1 className="title">{title}</h1>

          {data.map((item) => {
            return (
              <a
                href={item.url}
                target="__blank"
                className="item"
                key={item.name[lang]}
              >
                <div className="item__photo">
                  <img
                    src={item.image}
                    alt={`Logo of ${item.name[lang]}`}
                  ></img>
                </div>

                <div className="item__info">
                  <h2 className="role">
                    {type === "experiences" ? item.role[lang] : item.name[lang]}
                    <i className="fa fa-link role__link"></i>
                  </h2>
                  {type === "experiences" && (
                    <h3 className="company">{item.name[lang]}</h3>
                  )}

                  <p className="period">
                    {item.startedTime[lang]} - {item.endedTime[lang]}
                  </p>

                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: item.description[lang] }}
                  ></p>

                  <div className="skills">
                    {item.skills.map((skill) => {
                      return <span key={skill}>{skill}</span>;
                    })}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Timeline;
