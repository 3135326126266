import React from "react";
import { useTranslation } from "react-i18next";

import { Section } from "../Section/Section";

import "./Biography.scss";

const photo = require("../../images/photo.png");

const Biography = () => {
  const { t } = useTranslation();

  return (
    <Section backgroundColor="#252525">
      <div className="biography">
        <div className="biography__photo">
          <img src={photo} alt="Lucas Ribeiro: smiling and using headphones" />
        </div>

        <div className="biography__text">
          <p className="title">
            <span className="title__name">Lucas Ribeiro </span>
            <span className="title__role">{t("role")}</span>
          </p>

          <p className="bio">{t("bio")}</p>
        </div>
      </div>
    </Section>
  );
};

export default Biography;
