import React, { useState } from "react";
import { getLanguage } from "../../i18n/i18n";
import { Section } from "../Section/Section";
import "./Curiosity.scss";

const numbers = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
};

const curiosities = {
  en: [
    "I'm a São Paulo FC fan ⚫⚪🔴",
    "I once played Charlie Chaplin in a theater play 🎭",
    "I once played William Shakespeare in a theater play 🎭",
    "I've been playing the guitar since I was 9 🎸",
    "Prison Break is my favorite TV series 👨‍✈",
    "My favorite movie is Iron Man 🎬",
    "I started programming at the age of 14 ☕",
    "My favorite book is 'The Boy in the Striped Pajamas' 📖",
  ],
  pt: [
    "Sou São-paulino ⚫⚪🔴",
    "Já fui Charlie Chaplin em uma peça de teatro 🎭",
    "Já fui William Shakespeare em uma peça de teatro 🎭",
    "Toco violão desde os 9 anos 🎸",
    "Prison Break é minha série preferida 👨‍✈",
    "Meu filme favorito é Homem de Ferro 🎬",
    "Comecei a programar com 14 anos ☕",
    'Meu livro preferido é "O menino do pijama listrado" 📖',
  ],
  es: [
    "Soy seguidor del São Paulo FC ⚫⚪🔴",
    "Una vez interpreté a Charlie Chaplin en una obra de teatro 🎭",
    "Una vez interpreté a William Shakespeare en una obra de teatro 🎭",
    "Toco la guitarra desde los 9 años 🎸",
    "Prison Break es mi serie de televisión favorita 👨‍✈",
    "Mi película favorita es Iron Man 🎬",
    "Comencé a programar a los 14 años ☕",
    "Mi libro favorito es 'El niño con el pijama de rayas' 📖",
  ],
};

const Curiosity = () => {
  const [index, setIndex] = useState(0);
  const [usedIndexes, setUsedIndexes] = useState([0]);
  const [rotateFlag, setRotateFlag] = useState(false);

  const handleShuffling = () => {
    setRotateFlag(true);
    setTimeout(() => {
      setRotateFlag(false);
    }, 500);

    var value = Math.floor(Math.random() * curiosities[getLanguage()].length);

    if (usedIndexes.length === curiosities[getLanguage()].length) {
      setUsedIndexes([value]);
    } else {
      while (index === value || usedIndexes.includes(value)) {
        value = Math.floor(Math.random() * curiosities[getLanguage()].length);
      }
    }

    usedIndexes.push(value);
    setIndex(value);
  };

  return (
    <Section backgroundColor="#252525">
      <div className="curiosity">
        <button
          type="button"
          className={
            rotateFlag ? "curiosity__button--spin" : "curiosity__button"
          }
          onClick={() => handleShuffling()}
        >
          <i
            className={`fa fa-dice-${
              numbers[index] ? numbers[index] : numbers[5]
            } dice-icon`}
          ></i>
        </button>

        <div className="curiosity__text">
          {curiosities[getLanguage()][index]}
        </div>
      </div>
    </Section>
  );
};

export default Curiosity;
