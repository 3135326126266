export const resources = {
  en: {
    translations: {
      experiencesTitle: "Experiences",
      projectsTitle: "Projects",
      testimonialsTitle: "Testimonials",
      role: "Front-end Developer",

      bio: "I hold a Bachelor's degree in Computer Science and posses over 3 years of experience as a Front-end Developer, working with React, Vue and React Native. I am currently working at one of the largest e-commerce companies in Brazil, where we manage more than 40 million user sessions per month. I also have two apps published on Google Play, one of them with over 50,000 downloads. I am a fast-learner and easily adaptable professional, with excellent verbal and written communication skills.",

      contactMessage: "Find me at:",
    },
  },

  pt: {
    translations: {
      experiencesTitle: "Experiências",
      projectsTitle: "Projetos",
      testimonialsTitle: "Depoimentos",
      role: "Desenvolvedor Front-end",

      bio: "Formado em Ciência da Computação pela UTFPR, sou desenvolvedor front-end há mais de 3 anos trabalhando com frameworks Javascript como React, Vue e React Native. Atualmente sou desenvolvedor na Luizalabs, dentro do time responsável pela plataforma Netshoes, onde recebemos um volume mais de 40 milhões de sessões de usuário por mês. Também tenho dois aplicativos publicados na Google Play, um deles com mais de 50.000 downloads. Sou um profissional dedicado, comprometido e com excelentes habilidades de comunicação verbal e escrita.",

      contactMessage: "Me encontre em:",
    },
  },

  es: {
    translations: {
      curiosityButton: "Haga clic para atraer una curiosidad sobre mí",
      experiencesTitle: "Experiencias",
      projectsTitle: "Proyectos",
      testimonialsTitle: "Testimonios",
      role: "Desarrollador Front-end",

      bio: "Poseo una licenciatura en Ciencias de la Computación y tengo más de 3 años de experiencia como Desarrollador Front-end, trabajando con React, Vue y React Native. Actualmente trabajo en una de las empresas de comercio electrónico más grandes de Brasil. Además, tengo dos aplicaciones publicadas en la PlayStore, una de las cuales cuenta con más de 50,000 descargas. Soy una persona que aprende rápidamente y se adapta fácilmente, con excelentes habilidades de comunicación verbal y escrita.",

      contactMessage: "Encuéntrame en:",
    },
  },
};
