import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar/Navbar";
import Biography from "../../components/Biography/Biography";
import Testimonials from "../../components/Testimonials/Testimonials";
import Curiosity from "../../components/Curiosity/Curiosity";
import Contact from "../../components/Contact/Contact";
import BackToTop from "../../components/BackToTop/BackToTop";
import Footer from "../../components/Footer/Footer";
import Timeline from "../../components/Timeline/Timeline";

import data from "../../services/data";

import "./Home.scss";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <Navbar />
      <Biography />
      <Timeline
        id="experiences"
        type="experiences"
        title={t("experiencesTitle")}
        data={data.experiences}
      />

      <Timeline
        id="projects"
        type="projects"
        title={t("projectsTitle")}
        data={data.projects}
      />

      <Testimonials />
      <Curiosity />
      <Contact />
      <Footer />
      <BackToTop />
    </div>
  );
};

export default Home;
